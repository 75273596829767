import addDays from 'date-fns/addDays';
import Island from '../../components/shared/controls/Island/Island';
import startOfToday from 'date-fns/startOfToday';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import { Configuration } from '../../api/product';
import { format, parseISO } from 'date-fns';
import { FormEvent } from 'primereact/ts-helpers';
import { Link } from 'react-router-dom';
import { ExpeditionRecord, OrderApi } from '../../api/order';
import { RouteConfigComponentProps } from 'react-router-config';
import { useAuth } from '../../hooks/useAuth';
import { useCustomers } from '../../hooks/useCustomers';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DataView } from 'primereact/dataview';
import { createOrdersDetailPath } from '../../routing/appUrlGenerator';
import { FaRegCalendarAlt } from '@react-icons/all-files/fa/FaRegCalendarAlt';
import { CustomerSummary } from '../../api/customer';
import {
  CircularProgressbarWithChildren,
 } from 'react-circular-progressbar';
import { useLocalStorage } from 'usehooks-ts';

interface ExpeditionRecordEx extends ExpeditionRecord {
  customerName?: string;
}

const ExpeditionPage: FC<RouteConfigComponentProps<any>> = ({ match }) => {
  const { auth } = useAuth();
  const { t, i18n } = useTranslation(['common']);
  const { getCustomerSummaryById } = useCustomers();

  const [storedActiveDeliveryDate, setStoredActiveDeliveryDate] = useLocalStorage<Date>("expedition-active-date",
    startOfToday(),
  );

  const setActiveDeliveryDate = (val:Date|((old:Date)=>Date)) => {
    if (typeof val == "function") {
      if (typeof storedActiveDeliveryDate == "string") {
        let n = val(parseISO(storedActiveDeliveryDate))
        setStoredActiveDeliveryDate(n);
      } else {
        setStoredActiveDeliveryDate(val(storedActiveDeliveryDate))
      }
    } else {
      setStoredActiveDeliveryDate(val);
    }
  }

  const activeDeliveryDate = useMemo(()=>{
    if (typeof storedActiveDeliveryDate == "string") {
      return parseISO(storedActiveDeliveryDate);
    } else return storedActiveDeliveryDate;

  },[storedActiveDeliveryDate])

  const [lastUpdate, setLastUpdate] = useState<number>(0);
  const [trigger, setTrigger] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const [expeditionItems, setExpeditionItems] = useState<ExpeditionRecordEx[]>(
    [],
  );

  const orderApi = useMemo(
    () =>
      new OrderApi(
        new Configuration({
          basePath: process.env.REACT_APP_SERVICE_URL_ORDER,
          accessToken: auth?.jwt,
        }),
      ),
    [auth?.jwt],
  );

  const updateActiveDeliveryDate = useCallback(
    async (e: FormEvent<Date>) => {
      const newDate = e.target.value ?? startOfToday();

      if (newDate === activeDeliveryDate) return;
      setLoading(true);
      setActiveDeliveryDate(newDate);
    },
    [activeDeliveryDate],
  );

  useEffect(() => {
    setTrigger((prev) => (prev + 1) % 1000);
    const interval = setInterval(() => {
      setTrigger((prev) => (prev + 1) % 1000);
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!activeDeliveryDate) return;
    
    orderApi
      .getExpeditionData(activeDeliveryDate.toISOString())
      .then((response) => {
        if (response.status === 200) {
          let cachedCustomerData: Record<number, CustomerSummary> = {};

          const exData = response.data.map<ExpeditionRecordEx>((d) => {
            if (!cachedCustomerData[d.customerId]) {
              const customer = getCustomerSummaryById(d.customerId.toString());
              if (customer) 
                cachedCustomerData[d.customerId] = customer;
            }
            return {
              ...d,
              customerName: `${cachedCustomerData[d.customerId]?.firstName} ${
                cachedCustomerData[d.customerId]?.lastName
              }`.trim(),
            };
          });

          exData.sort(
            (a, b) =>
              new Date(b.deliveryDate).getTime() -
              new Date(a.deliveryDate).getTime(),
          );

          setExpeditionItems(exData);
        } else {
          setExpeditionItems([]);
        }
      })
      .finally(() => {
        setLoading(false);
        setLastUpdate(Date.now());
      });
  }, [activeDeliveryDate, auth, trigger]);

  const itemTemplate = (item: ExpeditionRecordEx) => {
    return (
      <div
        className={classNames(
          'bg-white border-1 border-black-alpha-20 my-2 flex flex-row w-12',
        )}
      >
        <div
          style={{
            width: '0.5rem',
            background: '#6688FF',
          }}
        ></div>
        <div className="flex align-items-center flex justify-content-center w-3 md:w-1">
          <CircularProgressbarWithChildren
            value={100}
            className={item.state}
            background={true}
            backgroundPadding={5}
            strokeWidth={5}
            text={item.progress}
            styles={{
              text: {
                dominantBaseline: 'middle',
                textAnchor: 'middle',
              },
            }}
          />
        </div>
        <div className="w-full flex flex-column gap-2 p-2">
          <div className="w-12 flex flex-column sm:flex-row">
            <div className="w-12 sm:w-6">
              <b className="text-primary">
                (&nbsp;#{String(item.orderId)}&nbsp;)&nbsp;
                <Link to={createOrdersDetailPath(String(item.orderId))}>
                  {item.title}
                </Link>
              </b>
            </div>
            <div className="w-12 sm:w-6 mt-2 sm:mt-0">
              <FaRegCalendarAlt className="mr-2" />
              {format(new Date(item.deliveryDate), 'HH:mm')}
            </div>
          </div>
          <div className="w-12 flex flex-row">
            <div className="w-12 sm:w-2">Ref:</div>
            <div className="w-12 sm:w-4">{item.reference}</div>
          </div>
          <div className="w-12 flex flex-row">
            <div className="w-12 sm:w-2">{t('common:customer')}:</div>
            <div className="w-12 sm:w-10">{item.customerName}</div>
          </div>
          {item.handledBy !== undefined && (
            <div className="w-12 flex flex-row mt-3 bg-primary p-3">
              <div className="w-12 sm:w-2 font-medium">
                {t('common:being-worked-on-by', { replace: { name: '' } })}
              </div>
              <div className="w-12 sm:w-10 font-medium">{item.handledBy}</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Island title={t('common:expedition')}>
      <div className="w-full flex flex-column py-2 gap-4">
        <div className="w-12 flex flex-column gap-2">
          <div className="w-12">
            <label htmlFor="deliveryDate">{t('common:delivery-date')}</label>
          </div>
          <div className="w-12">
            <div className="flex gap-2">
              <Button
                onClick={() =>{setLoading(true); setActiveDeliveryDate((old) => addDays(old, -1))}}
              >
                &lt;
              </Button>
              <Calendar
                value={activeDeliveryDate}
                onChange={updateActiveDeliveryDate}
                className="w-12 sm:w-3"
                inputId="deliveryDate"
                locale={i18n.language.substring(0, 2)}
              />
              <Button
                onClick={() => {setLoading(true); setActiveDeliveryDate((old) => addDays(old, 1))}}
              >
                &gt;
              </Button>
            </div>
          </div>
        </div>
        <div className="bg-white border-1 border-black-alpha-30 w-full p-2">
          <DataView
            header={`Expedition schema | ${expeditionItems.length} items`}
            value={expeditionItems}
            itemTemplate={itemTemplate}
            loading={loading}
          />
        </div>
        <div style={{ width: '100%', textAlign: 'right' }}>
          {lastUpdate != 0 && (
            <small>{t('common:last-update')}: {format(lastUpdate, 'HH:mm:ss')}</small>
          )}
        </div>
      </div>
    </Island>
  );
};

export default ExpeditionPage;

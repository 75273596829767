import { createAction } from 'redux-act';
import { ProductSummary, ProductModel, PostProductModelSpecification } from '../../api/product';

export interface MoveProductBuilderSpecParams {
    oldIndex: number;
    newIndex: number;
}

export interface UpdateProductMeta {
    name: string;
    version: number;
    description?: string;
}

export interface RemoveProductParams {
    sequence: number;
}

export const clearProduct = createAction("Clear product specification");
export const createAddProductElementAction = createAction<PostProductModelSpecification>("Add Product Element");
export const editProductElement = createAction<{ id: number, data: any }>("Edit Product Element");
export const createUpdateProductMetaAction = createAction<UpdateProductMeta>("Update Product META Data");
export const updateProductElementOrder = createAction<{}>("Update Product Element ordering");

export const createUpdateProductBuilderSpecAction = createAction<PostProductModelSpecification>('Update product builder spec');
export const createMoveProductBuilderSpecItem = createAction<MoveProductBuilderSpecParams>('Move product builder spec');
export const createGetProductsAction = createAction<ProductSummary[]>('Get products');
export const createGetProductByIdAction = createAction('Get product by id');
export const createUpdateProductAction = createAction<number>('Update product');

export const createUpdateProductBuilderAction = createAction<ProductModel>('Update product builder');
export const createProductBuilderErrorAction = createAction<boolean>('Set product builder error');
export const createRemoveProductElementAction = createAction<RemoveProductParams>("Delete Product Element");

export const createDeleteProductAction = createAction<number>('Delete product');

// @ts-nocheck

export const filterObjectKeys = (obj, predicate) =>
    Object.assign(
        ...Object.keys(obj)
            .filter((key) => predicate(key))
            .map((key) => ({ [key]: obj[key] })),
    );

export const definedValue = (v) => typeof v !== typeof undefined;
export const notEmptyValue = (v) => definedValue(v) && v !== '';

export function getStackTrace() {
    const error = new Error();
    const stack = error.stack
      .split('\n')
      .slice(2)
      .map((line) => line.replace(/\s+at\s+/, ''))
      .join('\n');
    return stack;
  }
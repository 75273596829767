import * as yup from 'yup';
import FormGroup from '../../../components/shared/controls/Formik/FormGroup';
import Island from '../../../components/shared/controls/Island/Island';
import startOfToday from 'date-fns/startOfToday';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { createHomePath } from '../../../routing/appUrlGenerator';
import { de, en, es, fr, nl, pl } from 'yup-locales';
import { Dropdown } from 'primereact/dropdown';
import {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';
import { RouteConfigComponentProps } from 'react-router-config';
import { showErrorNotification, showNotification } from '../../../utils/notification';
import { useAuth } from '../../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { useTenantContext } from '../../../context/TenantContext';
import { useTranslation } from 'react-i18next';
import {
  CalendarEvent,
  GetDeliveryById200Response,
  GetDeliveryById200ResponseCalendarIdsInner,
  OrderApi,
  Configuration as OrderApiConfiguration,
  OrderSummary,
  OrderTransport,
  OrderTransportByEnum,
  OrderTransportSameAddressEnum,
} from '../../../api/order';
import {
  CustomerApi,
  Configuration as CustomerApiConfiguration,
} from '../../../api/customer';

type DeliveryFormikValues = {
  calendarId?: string;
  summary?: string;
  description?: string;
  location?: string;
  deliveryDate?: Date;
  deliveryTime?: Date;
  orderId?: number;
};

const CreateDeliveryPageV2: FC<RouteConfigComponentProps<any>> = ({
  match,
}) => {
  let transportId = match.params.id;

  const { t, i18n } = useTranslation(['delivery', 'notifications']);
  const { auth, isOfficeUser, isAdminUser } = useAuth();
  const history = useHistory();

  const tenantContext = useTenantContext();

  // QUICK STOP
  if (!transportId || (!isOfficeUser() && !isAdminUser())) {
    history.push(createHomePath());
    return <></>;
  }

  /* STATES */
  const [calendars, setCalendars] = useState<
    GetDeliveryById200ResponseCalendarIdsInner[]
  >([]);
  const [reschedule, setReschedule] = useState<boolean>(false);
  const [eventData, setEventData] = useState<CalendarEvent | undefined>();
  const [formikInitialValues, setFormikInitialValues] =
    useState<DeliveryFormikValues>({});

  /* API CLIENTS */
  const orderApiOptions = useMemo(() => {
    return new OrderApiConfiguration({
      basePath: process.env.REACT_APP_SERVICE_URL_ORDER,
      accessToken: auth?.jwt,
    });
  }, [auth?.jwt]);

  const customerApiOptions = useMemo(() => {
    return new CustomerApiConfiguration({
      basePath: process.env.REACT_APP_SERVICE_URL_CUSTOMER,
      accessToken: auth?.jwt,
    });
  }, [auth?.jwt]);

  const orderApi = useMemo(
    () => new OrderApi(orderApiOptions),
    [orderApiOptions],
  );
  const customerApi = useMemo(
    () => new CustomerApi(customerApiOptions),
    [customerApiOptions],
  );

  /* FUNCS & CBS */
  const getDescription = (summary: OrderSummary, transport: OrderTransport) => {
    let description = `${t('open-balance')}: ${
      summary.orderTotal !== undefined && summary.paid !== undefined
        ? summary.orderTotal - summary.paid
        : ''
    }`;
    if (transport.internalComment)
      description += `, ${t('internal-remark')}: ${transport.internalComment}`;

    return description;
  };

  const createInitialEventValues = useMemo(
    () => async (event: GetDeliveryById200Response) => {
      const transport = event.transport;

      if (!transport?.orderId) return;
      const orderId = transport.orderId;

      const orderData = (await orderApi.getOrderById(orderId.toString()))?.data;
      if (!orderData.summary?.customerId) return;

      const summary = orderData.summary;
      const customerData = (
        await customerApi.getCustomerById(summary.customerId!)
      )?.data;
      if (!customerData) return;

      let defaultCalendar: string | undefined;
      let calendarId: string | undefined;

      calendarId = event.calendars ? event.calendars[0].value : '';
      if (tenantContext?.raw && tenantContext.raw['GCAL:DEFAULT-CALENDAR']) {
        defaultCalendar = String(tenantContext.raw['GCAL:DEFAULT-CALENDAR']);

        if (event.calendars?.find((d) => d.value === defaultCalendar))
          calendarId = defaultCalendar;
      }

      return {
        summary: `${
          transport.by === OrderTransportByEnum.PickUp
            ? t('type.pickup')
            : t('type.deliver')
        }${customerData.businessName ? ` ${customerData.businessName}` : ''} ${
          customerData.firstName
        } ${customerData.lastName}`,
        calendarId: calendarId,
        description: getDescription(summary, transport),
        location:
          transport.sameAddress === OrderTransportSameAddressEnum.No
            ? `${transport.street} ${transport.postalcode} ${transport.city}`
            : `${customerData.street} ${customerData.number}${
                customerData.numberSuffix ? ` ${customerData.numberSuffix}` : ''
              } ${customerData.postalcode} ${customerData.city}`,
        deliveryDate: new Date().toISOString(),
        orderId: orderId,
      };
    },
    [orderApi, customerApi, tenantContext],
  );

  const fetchTransportById = useMemo(
    () => async (transportId: string | number) => {
      if (!transportId) return;

      const eventData = (await orderApi.getDeliveryById(transportId.toString()))
        ?.data;
      if (eventData) {
        if (eventData.calendars) {
          setCalendars(eventData.calendars);
        }

        if (eventData.event) {
          setReschedule(true);
          if (
            eventData.event.deliveryDate !== eventData.transport?.deliveryDate
          ) {
            eventData.event.deliveryDate =
              eventData.transport?.deliveryDate ?? new Date().toISOString();
          }

          setEventData(eventData.event);
        } else {
          if (eventData.transport) {
            setReschedule(false);
            const initialEvent = await createInitialEventValues(eventData);
            setEventData(initialEvent);
          }
        }
      }
    },
    [orderApi],
  );

  const handleRemoveDelivery = useMemo(
    () => async (transportId: number) => {
      try {
        const response = await orderApi.deleteDelivery(String(transportId));
        if (response.status == 200) {
          showNotification(
            t('notifications:delivery-event-succesfully-removed'),
          );
          history.go(-1);
        }
      } catch {}
    },
    [orderApi],
  );

  const updateDelivery = useMemo(()=>async(evt:CalendarEvent)=> {
    let result = false;
    try {
        const response = await orderApi.updateDelivery(evt);
        if (response.status == 200) {
            result = true;
        }
    } catch {
        result = false;
    }
    return result;
  }, [orderApi])


  /* EFFECTS */
  useLayoutEffect(() => {
    fetchTransportById(transportId);
  }, [transportId]);

  useLayoutEffect(() => {
    if (eventData == undefined) return;
    const { deliveryDate, ...rest } = eventData;
    const d = deliveryDate ? new Date(deliveryDate) : new Date();

    const initialData: DeliveryFormikValues = {
      ...rest,
      deliveryDate: d,
      deliveryTime: d,
    };

    setFormikInitialValues(initialData);
  }, [eventData]);

  useEffect(() => {
    switch (i18n.language?.substring(0, 2)) {
      case 'nl':
        yup.setLocale(nl);
        break;
      case 'de':
        yup.setLocale(de);
        break;
      case 'fr':
        yup.setLocale(fr);
        break;
      case 'de':
        yup.setLocale(de);
        break;
      case 'es':
        yup.setLocale(es);
        break;
      case 'pl':
        yup.setLocale(pl);
        break;
      default:
        yup.setLocale(en);
        break;
    }
  }, [i18n.language]);

  const handleSubmission = useCallback(
    async (
      values: DeliveryFormikValues,
      helpers: FormikHelpers<DeliveryFormikValues>,
    ) => {
      const aDate = new Date();
      if (values.deliveryDate) {
        aDate.setFullYear(
          values.deliveryDate.getFullYear(),
          values.deliveryDate.getMonth(),
          values.deliveryDate.getDate(),
        );
      }

      if (values.deliveryTime) {
        aDate.setHours(
          values.deliveryTime.getHours(),
          values.deliveryTime.getMinutes(),
          0,
          0,
        );
      } else {
        aDate.setHours(0, 0, 0, 0);
      }

      if (aDate <= new Date()) {
        showNotification("Datum kan niet in het verleden liggen", "warning");
        return;
      }

      const submission: CalendarEvent = {
        calendarId: values.calendarId,
        description: values.description,
        extEventId: eventData?.extEventId,
        location: values.location,
        orderId: values.orderId,
        summary: values.summary,
        transportId: Number.parseInt(transportId),
        deliveryDate: aDate.toISOString(),
      };

       let isOk = await updateDelivery(submission);
       if (isOk) {
        showNotification(t('notifications:delivery-event-succesfully-updated'));
        history.go(-1);
       } else {
        showErrorNotification(t('notifications:delivery-event-update-failed'));
       }
    },
    [orderApi],
  );

  return (
    <Island
      className="card"
      title={t('detailsTitle')}
      onBackClick={() => history.go(-1)}
    >
      <Formik
        onSubmit={(values, helpers) => handleSubmission(values, helpers)}
        initialValues={formikInitialValues}
        enableReinitialize
        validationSchema={yup.object().shape({
          summary: yup.string().required(
            t('common:validation.fieldrequired', {
              replace: { field: t('summary') },
            }),
          ),
          location: yup.string().required(
            t('common:validation.fieldrequired', {
              replace: { field: t('location') },
            }),
          ),
          description: yup.string().required(
            t('common:validation.fieldrequired', {
              replace: { field: t('description') },
            }),
          ),
          deliveryDate: yup
            .date()
            .required(
              t('common:validation.fieldrequired', {
                replace: { field: t('deliveryDate') },
              }),
            )
            .min(startOfToday()),
          deliveryTime: yup.date().required(
            t('common:validation.fieldrequired', {
              replace: { field: t('deliveryTime') },
            }),
          ),
        })}
      >
        {(props) => (
          <>
            <Form>
              <FormGroup name="summary" label={t('summary')}>
                <Field
                  disabled={!isOfficeUser() && !isAdminUser()}
                  name="summary"
                  className="p-inputtext p-component"
                />
              </FormGroup>
              <FormGroup name="calendarId" label={t('calendar')}>
                <Dropdown
                  id="calendar"
                  name="calendarId"
                  disabled={!isOfficeUser() && !isAdminUser()}
                  options={calendars}
                  value={
                    props.values.calendarId || props.initialValues.calendarId
                  }
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
              </FormGroup>
              <FormGroup name="location" label={t('location')}>
                <Field
                  disabled={!isOfficeUser() && !isAdminUser()}
                  name="location"
                  className="p-inputtext p-component"
                />
              </FormGroup>
              <FormGroup name="description" label={t('description')}>
                <InputTextarea
                  disabled={!isOfficeUser() && !isAdminUser()}
                  className="p-component p-inputtext"
                  rows={4}
                  name="description"
                  value={props.values.description}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
              </FormGroup>
              <FormGroup name="deliveryDate" label={t('deliveryDate')}>
                <div className="flex gap-2">
                  <Calendar
                    className="w-8 md:w-2"
                    disabled={!isOfficeUser() && !isAdminUser()}
                    name="deliveryDate"
                    value={props.values.deliveryDate}
                    onChange={props.handleChange}
                    dateFormat="dd-mm-yy"
                    minDate={startOfToday()}
                    showWeek
                  />
                  <Calendar
                    className="w-8 md:w-2"
                    disabled={!isOfficeUser() && !isAdminUser()}
                    timeOnly
                    name="deliveryTime"
                    value={props.values.deliveryTime}
                    onChange={props.handleChange}
                  />
                </div>
              </FormGroup>
              {(isOfficeUser() || isAdminUser()) && (
                <div className="flex gap-3">
                  <Button type="submit" disabled={props.isSubmitting}>
                    {t(
                      `common:actions.${
                        eventData?.extEventId ? 'edit' : 'save'
                      }`,
                    )}
                  </Button>
                  <Button
                    type="button"
                    className="button-danger"
                    disabled={!reschedule}
                    onClick={() => handleRemoveDelivery(transportId)}
                  >
                    {t('common:actions.delete')}
                  </Button>
                </div>
              )}
            </Form>
          </>
        )}
      </Formik>
    </Island>
  );
};

export default CreateDeliveryPageV2;

import { useDispatch, useSelector } from 'react-redux';
import {
  DefaultApi as ProductApi,
  PostProductModelSpecification,
} from '../api/product/api';
import { GlobalState } from '../interfaces/redux/GlobalState';
import {
  createAddProductElementAction,
  createUpdateProductBuilderSpecAction,
  createMoveProductBuilderSpecItem,
  createGetProductsAction,
  createUpdateProductAction,
  clearProduct,
  createUpdateProductBuilderAction,
  UpdateProductMeta,
  createUpdateProductMetaAction,
  createProductBuilderErrorAction,
  createDeleteProductAction,
} from '../redux/action/productActionFactory';
import { showErrorNotification } from '../utils/notification';
import { useHistory } from 'react-router';
import {
  createProductsPath,
  createProductsDetailPath,
  createProductsNewPath,
} from '../routing/appUrlGenerator';
import { Configuration } from '../api/shared/configuration';
import { useMemo } from 'react';

export interface ProductBuilderFormValues {
  [id: string]: any;
}

export function useProduct() {
  const dispatch = useDispatch();
  const auth = useSelector((state: GlobalState) => state.auth);
  const productApi = useMemo(
    () =>
      new ProductApi(
        new Configuration({
          basePath: process.env.REACT_APP_SERVICE_URL_PRODUCT,
          accessToken: auth?.jwt,
        }),
      ),
    [auth?.jwt],
  );
  const product = useSelector((state: GlobalState) => state.product);
  const history = useHistory();

  async function getProducts(): Promise<void> {
    try {
      const response = await productApi.getProducts();

      dispatch(createGetProductsAction(response ? response.data : []));
    } catch (error: any) {
      if (error.response.status === 404) {
        return;
      }

      showErrorNotification(
        `Something went wrong while fetching products: ${error}`,
      );
    }
  }

  function addEmptyProductBuilderSpec() {
    dispatch(
      createAddProductElementAction({
        sequence: 0,
        sectionName: '',
        visibleFor: ['office', 'factory', 'customer'],
        mutableBy: ['office', 'factory'],
        trackTime: false,
      }),
    );
  }

  function updateProductBuilderSpec(values: PostProductModelSpecification) {
    dispatch(createUpdateProductBuilderSpecAction(values));
  }

  function moveProductBuilderSpecItem(oldIndex: number, newIndex: number) {
    dispatch(
      createMoveProductBuilderSpecItem({
        oldIndex,
        newIndex,
      }),
    );
  }

  async function getProductById(id: number) {
    try {
      const response = await productApi.getProductById(id.toString());

      dispatch(createUpdateProductBuilderAction(response.data));
    } catch (error) {}
  }

  async function createProduct() {
    history.push(createProductsPath());

    try {
      const response: any = await productApi.createProduct(product.builder);

      dispatch(createUpdateProductAction(response.data.productInsertId));
      clearProductBuilder();
    } catch (error) {
      showErrorNotification(
        `Something went wrong creating a product: ${error}`,
      );
      history.push(createProductsNewPath());
    }
  }

  async function updateProduct(id: string) {
    history.push(createProductsPath());

    try {
      if (
        product.builder.specifications.some(
          (x) => typeof x.formId === 'undefined',
        )
      )
        throw 'missing form definitions';
      await productApi.putProductId(id, product.builder);

      dispatch(createUpdateProductAction(parseInt(id)));
      clearProductBuilder();
    } catch (error) {
      showErrorNotification(
        `Something went wrong updating a product: ${error}`,
      );
      dispatch(createProductBuilderErrorAction(true));
      history.push(createProductsDetailPath(parseInt(id)));
      dispatch(createProductBuilderErrorAction(false));
    }
  }

  function updateProductMeta(update: UpdateProductMeta) {
    dispatch(createUpdateProductMetaAction(update));
  }

  function clearProductBuilder() {
    dispatch(clearProduct());
  }

  async function deleteProduct(id: number) {
    try {
      await productApi.deleteProduct(id.toString());

      dispatch(createDeleteProductAction(id));
    } catch (error) {
      showErrorNotification(
        `Something went wrong deleting a product: ${error}`,
      );
    }
  }

  return {
    spec: product.builder.specifications,
    getProducts,
    addEmptyProductBuilderSpec,
    updateProductBuilderSpec,
    moveProductBuilderSpecItem,
    product,
    getProductById,
    createProduct,
    updateProduct,
    updateProductMeta,
    clearProductBuilder,
    deleteProduct,
  };
}

import { useState, FC } from 'react';
import {
  createHomePath,
  createSearchPath,
  createTenantsPath,
  createTenantsNewPath,
  createUsersPath,
  createUsersNewPath,
  createFormsPath,
  createFormsNewPath,
  createShoppingPath,
  createArticlesPath,
  createCategoriesPath,
  createProductsPath,
  createProductsNewPath,
  createCustomersPath,
  createStatisticsWorkingHoursPath,
  createPlanningOverviewPath,
  createSalesMarginsStatisticsPath,
  createOrdersDeliveryPath,
  createAVPath,
  createConfigurationProcessEmailsPath,
  createMarketingPath,
  createTaskPath,
  createNewTaskPath,
  createConfigurationTenantSettingsPath,
  createWorkInProgressPath,
  createOrdersDeliveryCreateRoutePath,
  createExpeditionPath,
  createOrderSchedulePath,
} from '../../../../routing/appUrlGenerator';
import { useAuth } from '../../../../hooks/useAuth';
import AppSubmenu from './Submenu';
import { useOnMount } from '../../../../hooks/useUpdate';
import { getWeek } from 'date-fns';
import { useTranslation } from 'react-i18next';

type AppMenuProps = {
  onMenuItemClick: any;
  onLinkClick?: () => void;
};

let today = new Date();

const AppMenu: FC<AppMenuProps> = ({ onMenuItemClick, onLinkClick }) => {
  const [menuItems, setMenuItems] = useState<any>([]);
  const {
    isSystemAdmin,
    isTenantAdmin,
    isOfficeUser,
    isFactoryUser,
    isNotACustomer,
    isCustomerUser,
    isAdminUser,
    role,
    isExternal,
  } = useAuth();

  const { t, i18n } = useTranslation(['common']);

  useOnMount(() => {
    createMenu();
  }, [role, i18n.language]);

  async function createMenu() {
    const menu: any[] = [];
    menu.push({
      label: t('common:mainmenu.dashboard'),
      icon: 'pi pi-fw pi-home',
      to: createHomePath(),
    });

    if (isNotACustomer() && !isExternal()) {
      menu.push({
        label: t('common:search'),
        icon: 'pi pi-fw pi-search',
        to: createSearchPath(),
      });
    }

    if (isSystemAdmin() || isTenantAdmin() || isOfficeUser()) {
      menu.push({
        label: t('common:mainmenu.in-progress'),
        icon: 'pi pi-fw pi-info-circle',
        to: createWorkInProgressPath(),
      });

      menu.push({
        label: t('common:mainmenu.customermanagement'),
        icon: 'pi pi-fw pi-users',
        to: createCustomersPath(),
      });
    }

    const administrationMenuItems: any[] = [];

    if (isSystemAdmin() || isTenantAdmin() || isOfficeUser()) {
      administrationMenuItems.push({
        label: t('common:mainmenu.usermanagement'),
        icon: 'pi pi-fw pi-users',
        items: [
          {
            label: t('common:mainmenu.overview'),
            icon: 'pi pi-fw pi-table',
            to: createUsersPath(),
          },
          {
            label: t('common:mainmenu.createnew'),
            icon: 'pi pi-plus',
            to: createUsersNewPath(),
          },
        ],
      });
    }

    if (isOfficeUser() || isSystemAdmin() || isTenantAdmin()) {
      administrationMenuItems.push({
        label: t('common:mainmenu.formmanagement'),
        icon: 'pi pi-fw pi-file',
        items: [
          {
            label: t('common:mainmenu.overview'),
            icon: 'pi pi-fw pi-table',
            to: createFormsPath(),
          },
          {
            label: t('common:mainmenu.createnew'),
            icon: 'pi pi-plus',
            to: createFormsNewPath(),
          },
        ],
      });
    }

    if (isOfficeUser() || isSystemAdmin() || isTenantAdmin()) {
      administrationMenuItems.push({
        label: t('common:mainmenu.productmanagement'),
        icon: 'pi pi-palette',
        items: [
          {
            label: t('common:mainmenu.overview'),
            icon: 'pi pi-table',
            to: createProductsPath(),
          },
          {
            label: t('common:mainmenu.createnew'),
            icon: 'pi pi-plus',
            to: createProductsNewPath(),
          },
          {
            label: t('common:mainmenu.marketing'),
            icon: 'pi pi-send',
            to: createMarketingPath(),
          },
        ],
      });

      administrationMenuItems.push({
        label: t('common:mainmenu.taskmanagement'),
        icon: 'pi pi-fw pi-clock',
        items: [
          {
            label: t('common:mainmenu.overview'),
            icon: 'pi pi-table',
            to: createTaskPath(),
          },
          {
            label: t('common:mainmenu.createnew'),
            icon: 'pi pi-plus',
            to: createNewTaskPath(),
          },
        ],
      });
    }

    if (isNotACustomer()) {
      menu.push({
        label: t('common:mainmenu.stockmanagement'),
        icon: 'pi pi-fw pi-sitemap',
        items: [
          {
            label: t('common:mainmenu.stock.articles'),
            icon: 'pi pi-table',
            to: createArticlesPath(),
          },
          {
            label: t('common:mainmenu.stock.categories'),
            icon: 'pi pi-tag',
            to: createCategoriesPath(),
          },
        ],
      });
    }

    if (isSystemAdmin() || isTenantAdmin() || isOfficeUser()) {
      menu.push({
        label: t('common:mainmenu.statistics'),
        icon: 'pi pi-fw pi-chart-line',
        items: [
          {
            label: t('common:mainmenu.workingHours'),
            icon: 'pi pi-clock',
            to: createStatisticsWorkingHoursPath(
              today.getUTCFullYear(),
              getWeek(today, { weekStartsOn: 1, firstWeekContainsDate: 4 }),
            ),
          },
          {
            label: t('common:mainmenu.salesMargins'),
            icon: 'pi pi-euro',
            to: createSalesMarginsStatisticsPath(),
          },
        ].sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0)),
      });
    }

    if (isNotACustomer()) {
      let items = [
        {
          label: t('common:mainmenu.delivery'),
          icon: 'pi pi-map',
          to: createOrdersDeliveryPath(),
        },
      ];

      if (!isExternal() || !isFactoryUser()) {
        items.push({
          label: t('common:mainmenu.plan-routes'),
          icon: 'pi pi-map-marker',
          to: createOrdersDeliveryCreateRoutePath(),
        });
      }

      items.push({
        label: t('common:mainmenu.expedition'),
        icon: 'pi pi-truck',
        to: createExpeditionPath(),
      });

      if (isSystemAdmin() || isTenantAdmin() || isOfficeUser()) {
        items = [
          {
            label: t('common:order-schedule'),
            icon: 'pi pi-fw pi-calendar',
            to: createOrderSchedulePath(),
          },
          {
            label: t('common:mainmenu.workplanning'),
            icon: 'pi pi-calendar',
            to: createPlanningOverviewPath(),
          },

          ...items,
        ];
      }
      
      if (!isExternal()) {
      menu.push({
        label: t('common:mainmenu.planning'),
        icon: 'pi pi-calendar',
        items: items.sort((a, b) =>
          a.label > b.label ? 1 : b.label > a.label ? -1 : 0,
        ),
      });
    }
      if (!isExternal()) {
        menu.push({
          /*label: t('common:mainmenu.utils'),
        icon: 'pi pi-fw pi-folder',
        items: [
          {*/
          label: t('common:mainmenu.shoppinglist'),
          icon: 'pi pi-fw pi-shopping-cart',
          to: createShoppingPath(),
          // },
          //],
        });
      }
    }

    if (isSystemAdmin() || isTenantAdmin() || isOfficeUser()) {
      menu.push({
        label: t('common:mainmenu.administration'),
        icon: 'pi pi-fw pi-book',
        items: administrationMenuItems.sort((a, b) =>
          a.label > b.label ? 1 : b.label > a.label ? -1 : 0,
        ),
      });
    }

    if (isCustomerUser() || isAdminUser()) {
      menu.push({
        label: t('common:mainmenu.termsandconditions'),
        icon: 'pi pi-book',
        to: createAVPath(),
      });
    }

    if (isCustomerUser()) {
      menu.push({
        label: t('common:mainmenu.emailSettings'),
        icon: 'pi pi-fw pi-cog',
        to: createConfigurationProcessEmailsPath(),
      });
    }

    if (isSystemAdmin() || isTenantAdmin()) {
      let superMenuItems: any[] = [];
      if (isSystemAdmin()) {
        superMenuItems.push({
          label: t('common:mainmenu.tenantmanagement'),
          icon: 'pi pi-fw pi-id-card',

          items: [
            {
              label: t('common:mainmenu.overview'),
              icon: 'pi pi-fw pi-table',
              to: createTenantsPath(),
            },
            {
              label: t('common:mainmenu.createnew'),
              icon: 'pi pi-plus',
              to: createTenantsNewPath(),
            },
          ],
        });
      }

      superMenuItems.push({
        icon: 'pi pi-fw pi-cog',
        label: t('common:mainmenu.tenantSettings'),
        to: createConfigurationTenantSettingsPath(),
      });

      menu.push({
        label: t('common:mainmenu.superadmin'),
        icon: 'pi pi-fw pi-sliders-v',
        items: superMenuItems,
      });
    }

    setMenuItems(menu);
  }

  return (
    <div className="layout-menu-container">
      <AppSubmenu
        items={menuItems}
        className="layout-menu"
        onMenuItemClick={onMenuItemClick}
        root={true}
        onLinkClick={onLinkClick}
      />
    </div>
  );
};

export default AppMenu;

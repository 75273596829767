interface TenantConfiguration {
    id: string;
    theme: string;
    appTitle?: string;
    avDocuments: { avCategory: 'B2C' | 'B2B', documentName: string, fileName: string }[]
}

export const tenantConfiguration: { [host: string]: TenantConfiguration } = {
    'localhost:4001': {
        // id: 'T_WeijBV_CQL8waK',
        // theme: 'weijbv',
        // appTitle: 'Scope | Weij BV | LOCALHOST',
        
        // id: 'T_HandigeKasten_qkKtIM0',
        // theme: 'handigekasten',
        // appTitle: 'HandigeKasten.nl | Powered by Scope' ,
        // avDocuments: [ 
        //     { avCategory: 'B2B', documentName: 'Algemene voorwaarden zakelijk', fileName: 'AV B2B.pdf' },
        //     { avCategory: 'B2C', documentName: 'Algemene voorwaarden consument', fileName: 'AV B2C.pdf' }
        // ]        
        // id: 'T_HaVeWood_Xduu41v',
        // theme: 'havewood',
        // appTitle: 'Ha-Ve wood | Powered by Scope' ,

        // id: 'T_Sandbox_WsoUErf',
        // theme: 'sandbox',
        // appTitle: 'Sandbox | Powered by Scope',

        // id: 'T_ElderOak_kxr_ojc',
        // theme: 'elderoak',
        // appTitle: 'Scope | Elder Oak',

        id: 'SATUP',
        theme: 'default',
        appTitle: 'Scope Management HUB',
        avDocuments: [
            { avCategory: 'B2B', documentName: 'Algemene voorwaarden zakelijk', fileName: 'AV B2B.pdf' },
            { avCategory: 'B2C', documentName: 'Algemene voorwaarden consument', fileName: 'AV B2C.pdf' }
        ]
    },
    'scope-application-dev.s3-website-eu-west-1.amazonaws.com': {
        id: 'T_WeijBV_CQL8waK',
        theme: 'weijbv',
        appTitle: 'Scope | Weij BV | DEV',
        // id: 'SATUP',
        // theme: 'default',
        // appTitle: 'Scope Management HUB',
        avDocuments: [
            { avCategory: 'B2B', documentName: 'Algemene voorwaarden zakelijk', fileName: 'AV B2B.pdf' },
            { avCategory: 'B2C', documentName: 'Algemene voorwaarden consument', fileName: 'AV B2C.pdf' }
        ]
    },
    'hub.scope.mwsoftware.nl': {
        id: 'SATUP',
        theme: 'default',
        appTitle: 'Scope Management HUB',
        avDocuments: []
    },
    'sandbox.scope.mwsoftware.nl': {
        id: 'T_Sandbox_WsoUErf',
        theme: 'sandbox',
        appTitle: 'Sandbox | Powered by Scope',
        avDocuments: []
    },
    'handigekasten-nl.scope.mwsoftware.nl': {
        id: 'T_HandigeKasten_qkKtIM0',
        theme: 'handigekasten',
        appTitle: 'HandigeKasten.nl | Powered by Scope' ,
        avDocuments: []
    },
    'scope.handigekasten.nl': {
        id: 'T_HandigeKasten_qkKtIM0',
        theme: 'handigekasten',
        appTitle: 'HandigeKasten.nl | Powered by Scope' ,
        avDocuments: []
    },
    'have-wood.scope.mwsoftware.nl': {
        id: 'T_HaVeWood_Xduu41v',
        theme: 'havewood',
        appTitle: 'Ha-Ve wood | Powered by Scope' ,
        avDocuments: []
    },
    'scope.havewood.nl': {
        id: 'T_HaVeWood_Xduu41v',
        theme: 'havewood',
        appTitle: 'Ha-Ve wood | Powered by Scope' ,
        avDocuments: []
    },
    'vanhalteren.scope.mwsoftware.nl': {
        id: 'SATUP',
        theme: 'vanhalteren',
        avDocuments: []
    },
    'scope.vansloophout.com': {
        id: 'T_VanSloophoutcom_iW7VY3G',
        theme: 'vansloophout',
        appTitle: 'VanSloophout.com Order Tracking',
        avDocuments: []
    },
    'scope.weijbv.nl': {
        id: 'T_WeijBV_CQL8waK',
        theme: 'weijbv',
        appTitle: 'Scope | Weij BV',
        avDocuments: [
            { avCategory: 'B2B', documentName: 'Algemene voorwaarden zakelijk', fileName: 'AV B2B.pdf' },
            { avCategory: 'B2C', documentName: 'Algemene voorwaarden consument', fileName: 'AV B2C.pdf' }
        ]
    },
    'scope.elder-oak.com': {
        id: 'T_ElderOak_kxr_ojc',
        theme: 'elderoak',
        appTitle: 'Elder Oak | Powered by Scope',
        avDocuments: []
    },
    'elder-oak.scope.mwsoftware.nl': {
        id: 'T_ElderOak_kxr_ojc',
        theme: 'elderoak',
        appTitle: 'Elder Oak | Powered by Scope',
        avDocuments: []
    }
};

export function getTenantConfiguration(): TenantConfiguration {
    return tenantConfiguration[window.location.host];
}

// tslint:disable
/**
 * Product API
 * Facilitates the creation of \"products\"
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@vansloophout.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from '../shared/configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from '../shared/base';

/**
 * 
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    error?: string;
}
/**
 * 
 * @export
 * @interface Healthy
 */
export interface Healthy {
    /**
     * 
     * @type {string}
     * @memberof Healthy
     */
    service?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Healthy
     */
    isAlive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Healthy
     */
    isHealthy?: boolean;
}
/**
 * 
 * @export
 * @interface PostProductModelSpecification
 */
export interface PostProductModelSpecification {
    /**
     * 
     * @type {number}
     * @memberof PostProductModelSpecification
     */
    sequence: number;
    /**
     * 
     * @type {string}
     * @memberof PostProductModelSpecification
     */
    sectionName: string;
    /**
     * 
     * @type {string}
     * @memberof PostProductModelSpecification
     */
    formId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostProductModelSpecification
     */
    visibleFor: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostProductModelSpecification
     */
    mutableBy: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PostProductModelSpecification
     */
    trackTime?: boolean;
}
/**
 * 
 * @export
 * @interface ProductModel
 */
export interface ProductModel {
    /**
     * 
     * @type {Array<PostProductModelSpecification>}
     * @memberof ProductModel
     */
    specifications: Array<PostProductModelSpecification>;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductModel
     */
    visibleFor?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductModel
     */
    mutableBy?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductModel
     */
    trackTime?: boolean;
}
/**
 * 
 * @export
 * @interface ProductSummary
 */
export interface ProductSummary {
    /**
     * 
     * @type {number}
     * @memberof ProductSummary
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSummary
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSummary
     */
    sequence?: number
    /**
     * 
     * @type {number}
     * @memberof ProductSummary
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSummary
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface PutProductModel
 */
export interface PutProductModel {
    /**
     * 
     * @type {Array<PutProductModelSpecification>}
     * @memberof PutProductModel
     */
    specifications?: Array<PutProductModelSpecification>;
    /**
     * 
     * @type {string}
     * @memberof PutProductModel
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof PutProductModel
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof PutProductModel
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PutProductModel
     */
    visibleFor?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PutProductModel
     */
    mutableBy?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PutProductModel
     */
    trackTime?: boolean;
}
/**
 * 
 * @export
 * @interface PutProductModelSpecification
 */
export interface PutProductModelSpecification {
    /**
     * 
     * @type {number}
     * @memberof PutProductModelSpecification
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof PutProductModelSpecification
     */
    sequence?: number;
    /**
     * 
     * @type {string}
     * @memberof PutProductModelSpecification
     */
    sectionName?: string;
    /**
     * 
     * @type {string}
     * @memberof PutProductModelSpecification
     */
    formId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PutProductModelSpecification
     */
    visibleFor?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PutProductModelSpecification
     */
    mutableBy?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PutProductModelSpecification
     */
    trackTime?: boolean;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new product on the system
         * @summary Create new product
         * @param {ProductModel} [productModel] Full description of the product to create (including meta data)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (productModel?: ProductModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/product`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof productModel !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(productModel !== undefined ? productModel : {}) : (productModel || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete article
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteProduct.');
            }
            const localVarPath = `/products/product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/health`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves product details for the given product Id (within tenant context)
         * @summary Get product details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProductById.');
            }
            const localVarPath = `/products/product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a list of products from the system\\n\\nReturns only the products that are available for the logged-in tenant
         * @summary Fetch product list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/products`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates given product with the new specification
         * @summary Update product specification
         * @param {string} id 
         * @param {PutProductModel} [putProductModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProductId: async (id: string, putProductModel?: PutProductModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putProductId.');
            }
            const localVarPath = `/products/product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof putProductModel !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(putProductModel !== undefined ? putProductModel : {}) : (putProductModel || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Create a new product on the system
         * @summary Create new product
         * @param {ProductModel} [productModel] Full description of the product to create (including meta data)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(productModel?: ProductModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createProduct(productModel, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary delete article
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProduct(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteProduct(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Healthy>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getHealth(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves product details for the given product Id (within tenant context)
         * @summary Get product details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductModel>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProductById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Fetch a list of products from the system\\n\\nReturns only the products that are available for the logged-in tenant
         * @summary Fetch product list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProducts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductSummary>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProducts(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Updates given product with the new specification
         * @summary Update product specification
         * @param {string} id 
         * @param {PutProductModel} [putProductModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putProductId(id: string, putProductModel?: PutProductModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).putProductId(id, putProductModel, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Create a new product on the system
         * @summary Create new product
         * @param {ProductModel} [productModel] Full description of the product to create (including meta data)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(productModel?: ProductModel, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).createProduct(productModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete article
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct(id: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: any): AxiosPromise<Healthy> {
            return DefaultApiFp(configuration).getHealth(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves product details for the given product Id (within tenant context)
         * @summary Get product details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById(id: string, options?: any): AxiosPromise<ProductModel> {
            return DefaultApiFp(configuration).getProductById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a list of products from the system\\n\\nReturns only the products that are available for the logged-in tenant
         * @summary Fetch product list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts(options?: any): AxiosPromise<Array<ProductSummary>> {
            return DefaultApiFp(configuration).getProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * Updates given product with the new specification
         * @summary Update product specification
         * @param {string} id 
         * @param {PutProductModel} [putProductModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProductId(id: string, putProductModel?: PutProductModel, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).putProductId(id, putProductModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Create a new product on the system
     * @summary Create new product
     * @param {ProductModel} [productModel] Full description of the product to create (including meta data)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createProduct(productModel?: ProductModel, options?: any) {
        return DefaultApiFp(this.configuration).createProduct(productModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete article
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProduct(id: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Determine service health
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHealth(options?: any) {
        return DefaultApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves product details for the given product Id (within tenant context)
     * @summary Get product details
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProductById(id: string, options?: any) {
        return DefaultApiFp(this.configuration).getProductById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a list of products from the system\\n\\nReturns only the products that are available for the logged-in tenant
     * @summary Fetch product list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProducts(options?: any) {
        return DefaultApiFp(this.configuration).getProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates given product with the new specification
     * @summary Update product specification
     * @param {string} id 
     * @param {PutProductModel} [putProductModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public putProductId(id: string, putProductModel?: PutProductModel, options?: any) {
        return DefaultApiFp(this.configuration).putProductId(id, putProductModel, options).then((request) => request(this.axios, this.basePath));
    }

}


